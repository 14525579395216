h1 {
  font-size: 20px;
}

form {
  font-size: 15px;
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

form input {
  font-size: 15px;
  line-height: 1.3;
  padding: 5px 10px;
}

button {
  font-size: 15px;
  line-height: 1.3;
  padding: 5px 10px;
}

.line {
  display: block;
  flex: 1;
  margin: 10px;
}

.errors {
  color: red;
}

label {
  font-size: 17px;
  color: darkgrey;
}

label.fancy input {
  font-size: 18px;
  border: none;
  border-bottom: 2px rgba(121, 121, 121, 0.2) solid;
  margin-bottom: 1px;
  margin-left: 6px;
  padding: 6px 12px;
  transition: border-color 0.5s;
}

label.fancy input:focus {
  outline: none;
  border-bottom: 3px rgba(255, 174, 32, 1) solid;
  margin-bottom: 0px;
}

a {
  font-size: 12px;
}


